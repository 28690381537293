/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ErrorMessage, Formik, FieldArray } from 'formik';
import * as Yup from 'yup';

import { Spacer } from '../Common/styled/Spacer';
import { Button } from '../Common/styled/Button';
import { Row, Column } from '../Common/styled/Groups';
import { Dropdown } from '../Form/Dropdown/Dropdown';
import { Error, Form, Field, Label, Submit } from '../Common/styled/Form';
import { Dropzone } from '../Common/Dropzone/Dropzone';
import { protocolQuestionTypeList, protocolStateList } from '../../utils/ProtocolUtils';
import { HighlightedText } from '../Common/styled/HighlightedText';
import { Heading } from '../Common/styled/Heading';
import { AlertUI } from '../../componentsUI/Alert';


export const ProtocolForm = ({ protocol, submitLabel, handleSubmit = () => { }, serverError = false }) => {
  const { t } = useTranslation();

  const newOption = {
    optionText: '',
    score: 0,
  };

  const newQuestion = {
    type: 'TEXT',
    question: '',
    options: [newOption],
    helpText: '',
    helpPictures: [],
  };

  function createOption(option) {
    return {
      optionText: option.optionText,
      score: option.score,
    };
  }
  function createQuestion(question) {
    return {
      type: question.type,
      question: question.question,
      options: question.options.map((option) => createOption(option)),
      helpText: question.helpText,
      helpPictures: question.helpPictures.length ? [{ duplicateUuid: question.helpPictures[0].uuid }] : [],
    };
  }

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(t('required.field')),
    minDaysToVisit: Yup.number().required(t('required.field')).min(1, t('number.too.low')),
    questions: Yup.array().of(Yup.object().shape({
      question: Yup.string().required(t('required.field')),
    })),
  });

  const initialValues = {
    name: protocol ? `${protocol.name} 2` : '',
    questions: protocol ? protocol.questions.map((question) => createQuestion(question)) : [newQuestion],
    minDaysToVisit: protocol ? protocol.minDaysToVisit : 7,
    state: protocol ? protocol.state : '2',
  };

  return (
    <>
      <Formik enableReinitialize initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
        {(props) => (
          <Form autoComplete="off" onSubmit={props.handleSubmit}>
            <Row className="mb-4">
              <Column long>
                <Label htmlFor="name">{t('protocol.name')}</Label>
                <Field
                  name="name"
                  placeholder={t('name')}
                  value={props.values.name}
                  onChange={props.handleChange}
                />
                <ErrorMessage name="name" component={Error} />
              </Column>
              <Spacer />
              <Column>
                <Label htmlFor="minDaysToVisit">{`${t('min.days.to.visit')} *`}</Label>
                <Field
                  type="number"
                  name="minDaysToVisit"
                  placeholder={t('days')}
                  value={props.values.minDaysToVisit}
                  onChange={props.handleChange}
                />
                <ErrorMessage name="minDaysToVisit" component={Error} />
              </Column>
            </Row>

            <Row className="mb-4">
              <Column>
                <small style={{ color: '#aaa' }}>
                  {`* ${t('min.days.to.visit.details')}`}
                </small>
              </Column>
            </Row>

            <Row className="mb-5">
              <Column>
                <Label htmlFor="state">{t('protocol.privacy')}</Label>
                <Dropdown
                  name="state"
                  defaultValue={props.values.state}
                  options={protocolStateList}
                  onChange={props.handleChange}
                />
                <ErrorMessage name="state" component={Error} />
              </Column>
            </Row>

            <Row className="mt-5 mb-2-4">
              <Column>
                <Heading>
                  <HighlightedText>
                    {`${t('protocol.questions')}...`}
                  </HighlightedText>
                </Heading>
              </Column>
            </Row>

            <FieldArray name="questions">
              {(arrayHelpersQuestions) => (
                <div className="mb-4">
                  {props.values.questions.map((question, questionIndex) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <div className="mb-5" key={questionIndex}>
                      <h4 className="mt-0 mb-3">{`${t('question')} ${questionIndex + 1}`}</h4>

                      <Row className="mb-4">
                        <Column>
                          <Label htmlFor={`questions[${questionIndex}].type`}>{t('type')}</Label>
                          <Dropdown
                            name={`questions[${questionIndex}].type`}
                            defaultValue={question.type}
                            options={protocolQuestionTypeList}
                            onChange={props.handleChange}
                          />
                          <ErrorMessage name={`questions[${questionIndex}].type`} component={Error} />
                        </Column>
                      </Row>

                      <Row className="mb-4">
                        <Column>
                          <Label htmlFor={`questions[${questionIndex}].question`}>{t('question')}</Label>
                          <Field
                            name={`questions[${questionIndex}].question`}
                            placeholder={t('question')}
                            value={question.question}
                            onChange={props.handleChange}
                          />
                          <ErrorMessage name={`questions[${questionIndex}].question`} component={Error} />
                        </Column>
                      </Row>

                      {question.type === 'OPTIONS'
                        && (
                          <FieldArray name={`questions[${questionIndex}].options`}>
                            {(arrayHelpersOptions) => (
                              <div className="mb-4">
                                {question.options.map((option, optionIndex) => (
                                  // eslint-disable-next-line react/no-array-index-key
                                  <div key={optionIndex} className="ml-4">
                                    <Row className="mb-4">
                                      <Column>
                                        <Label htmlFor={`questions[${questionIndex}].options[${optionIndex}].optionText`}>
                                          {`${t('option')} ${optionIndex + 1}`}
                                        </Label>
                                        <Field
                                          name={`questions[${questionIndex}].options[${optionIndex}].optionText`}
                                          placeholder={`${t('option')} ${optionIndex + 1}`}
                                          value={option.optionText}
                                          onChange={props.handleChange}
                                        />
                                        <ErrorMessage name={`questions[${questionIndex}].options[${optionIndex}].optionText`} component={Error} />
                                      </Column>
                                      <Spacer />
                                      <Column>
                                        <Label htmlFor={`questions[${questionIndex}].options[${optionIndex}].score`}>
                                          {t('score')}
                                        </Label>
                                        <Field
                                          type="number"
                                          name={`questions[${questionIndex}].options[${optionIndex}].score`}
                                          placeholder={t('score')}
                                          value={option.score}
                                          onChange={props.handleChange}
                                        />
                                        <ErrorMessage name={`questions[${questionIndex}].options[${optionIndex}].score`} component={Error} />
                                      </Column>
                                    </Row>
                                  </div>
                                ))}
                                <Button type="button" className="my-0" onClick={() => arrayHelpersOptions.push(newOption)}>{t('add.option')}</Button>
                              </div>
                            )}
                          </FieldArray>
                        )}

                      {question.type === 'PICTURE'
                        && (
                          <div>
                            <Row className="mb-4">
                              <Column>
                                <Label htmlFor={`questions[${questionIndex}].helpText`}>{t('help.text')}</Label>
                                <Field
                                  name={`questions[${questionIndex}].helpText`}
                                  placeholder={t('help.text')}
                                  value={question.helpText}
                                  onChange={props.handleChange}
                                />
                                <ErrorMessage name={`questions[${questionIndex}].helpText`} component={Error} />
                              </Column>
                            </Row>
                            <Row className="mb-4">
                              <Column>
                                <Label htmlFor={`questions[${questionIndex}].helpPictures`}>{t('help.file')}</Label>
                                <Dropzone
                                  accept="image/jpg, image/gif, image/png, image/jpeg, video/mp4, video/3gpp, video/quicktime"
                                  setFile={(image) => props.setFieldValue(`questions[${questionIndex}].helpPictures[0].picture`, image)}
                                  currentFileUrl={protocol && protocol.questions[questionIndex] && protocol.questions[questionIndex].helpPictures[0]
                                    ? protocol.questions[questionIndex].helpPictures[0].picture
                                    : ''}
                                />
                                <ErrorMessage
                                  name={`questions[${questionIndex}].helpPictures`}
                                  component={Error}
                                />
                              </Column>
                            </Row>
                          </div>
                        )}
                    </div>
                  ))}
                  <Button
                    type="button"
                    className="my-0"
                    onClick={() => arrayHelpersQuestions.push(newQuestion)}
                  >
                    {t('add.question')}
                  </Button>
                </div>
              )}
            </FieldArray>

            {serverError ? <AlertUI severity="error" title="Error">{t('server.error')}</AlertUI> : null}

            <div className="mt-5">
              <Submit className="m-0" type="submit">{submitLabel}</Submit>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};
