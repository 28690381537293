import React, { useContext } from 'react';

import { useTranslation } from 'react-i18next';
import { ThemeContext } from 'styled-components';
import IcomoonReact from 'icomoon-react';
import icons from '../../../icons/cloud.json';

import { DropdownWrapper, DropdownBox } from './styled/DropdownStyles';

export const Dropdown = ({
  name,
  options = [],
  onChange = () => {},
  onClick = () => {},
  defaultValue,
  isSmall,
  uncontrolled = true,
}) => {
  const themeContext = useContext(ThemeContext);
  const { t } = useTranslation();
  const selected = defaultValue || (options.length && options[0].value);

  const generateOptions = () => (
    options.map((option, index) => (
      <option
        key={index.toString()}
        value={option.value}
        label={t(option.label)}
      >
        {t(option.label)}
      </option>
    ))
  );

  return (
    <DropdownWrapper isSmall={isSmall}>
      {
        uncontrolled
          ? (
            <DropdownBox
              name={name}
              defaultValue={selected}
              onChange={(e) => onChange(e)}
              onClick={(e) => onClick(e)}
            >
              { generateOptions() }
            </DropdownBox>
          )
          : (
            <DropdownBox
              name={name}
              value={selected}
              onChange={(e) => onChange(e)}
              onClick={(e) => onClick(e)}
            >
              { generateOptions() }
            </DropdownBox>
          )
      }
      <IcomoonReact
        iconSet={icons}
        color={themeContext.colors.gray400}
        size={14}
        icon="arrow_down"
      />
    </DropdownWrapper>
  );
};
