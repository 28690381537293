import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { navigate } from 'gatsby';
import { useSelector } from 'react-redux';
import { parseGraphqlErrors } from '../../utils/FormikUtils';

import { FullViewHeader as Header } from '../Common/FullViewHeader';
import Loading from '../Common/Loading';
import { isEmpty } from '../../utils/ObjectUtils';
import { GET_PROTOCOLS, GET_PROTOCOL, CREATE_PROTOCOL } from '../../queries/Protocols/Protocols';
import { ProtocolForm } from './ProtocolForm';

export const ProtocolEditView = ({ protocolId }) => {
  const { t } = useTranslation();
  const hospitalUuid = useSelector((state) => state.hospital.uuid);
  const [serverError, setServerError] = useState(false);

  const { loading, data } = useQuery(
    GET_PROTOCOL,
    { variables: { uuid: protocolId } },
  );

  const [createProtocol, { loading: updating }] = useMutation(
    CREATE_PROTOCOL,
    {
      onCompleted: () => {
        navigate('/protocols');
      },
      refetchQueries: [{ query: GET_PROTOCOLS, variables: { hospitalUuid } }],
      awaitRefetchQueries: true,
    },
  );

  const handleSubmit = async (values, { setErrors }) => {
    setServerError(false);
    try {
      await createProtocol({ variables: { input: { ...values, ...{ hospitalUuid } } } });
    } catch (errors) {
      const formikErrors = parseGraphqlErrors(errors.graphQLErrors, t);
      if (
        Object.keys(formikErrors).length === 0
        && formikErrors.constructor === Object
      ) {
        setServerError(true);
      } else {
        setErrors(formikErrors);
      }
    }
  };

  return (
    <>
      <Header title={t('edit.protocol')} />
      <small className="mb-3">*Edit a protocol creates a new one. Set a new name.</small>
      {loading || updating || isEmpty(data)
        ? <Loading />
        : <ProtocolForm protocol={data.protocol} submitLabel={t('create.and.save')} handleSubmit={handleSubmit} serverError={serverError} />}
    </>
  );
};
