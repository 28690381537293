import React from 'react';

import App from '../../components/App';
import { EmptyPage } from '../../components/Common/styled/EmptyPage';
import { ProtocolEditView } from '../../components/Protocols/ProtocolEditView';

export default ({ protocolId }) => (
  <App>
    <EmptyPage>
      <ProtocolEditView protocolId={protocolId} />
    </EmptyPage>
  </App>
);
