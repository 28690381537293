import styled, { css } from 'styled-components';

import { sizes } from '../../../../styles/variables';

export const DropdownWrapper = styled.div`
  min-width: 50%;
  position: relative;
  display: flex;
  align-items: center;
  ${(props) => props.isSmall && css`margin-right: 50%;`};

  svg {
    position: absolute;
    right: 12px;
    pointer-events: none;
  }

  @media (max-width: ${sizes.xsMax}) {
    margin: 5% 0;
  }
`;

export const Dropdown = styled.select`
  padding: 7px;
  background-color: transparent;
  outline-style: none;
  text-align-last: center;
  cursor: pointer;

  // Avoid icon appearing on chrome and firefox
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: '';
`;

export const DropdownBox = styled(Dropdown)`
  width: 100%;
  min-height: 25px;
  border-radius: 0;
  border: 1px solid ${(props) => props.theme.colors.gray600};
`;
